@use 'src/sass/variables' as *;
@use 'src/sass/extends';

.header {
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid rgba($secondaryLighten, 0.4);
}
.title {
  @extend %title--sm;
  text-align: center;
  color: white;
  margin-bottom: 0.5rem;
}
.text {
  @extend %body;
  line-height: 1.5;
  color: $white85;
  b {
    @extend %body--bold;
  }
}
.content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.subtitle {
  @extend %body--lg;
  font-weight: bold;
  color: $primary;
  margin-bottom: 0.5rem;
}
.list {
  @extend %body;
  line-height: 1.5;
  color: $white85;
  li {
    margin-left: 1rem;
    list-style: disc;
    margin-bottom: 0.5rem;
  }
  b {
    @extend %body--bold;
  }
}
