@use 'src/sass/variables' as *;
@use 'src/sass/extends';
@use 'src/sass/mixins';

.container {
  height: calc(100vh - 5rem);
  margin-bottom: -1.5rem;
}
.options {
  display: flex;
  gap: 0.75rem;
  margin-bottom: 0.25rem;
}
.content {
  height: 100%;
  overflow: hidden;
}
.crop {
  height: 100%;
  overflow: hidden;
}
.results {
  align-items: start;
  display: grid;
  grid-auto-rows: min-content;
  grid-gap: 0.75rem;
  height: 100%;
  overflow: auto;
  padding-right: 40px;
  padding-bottom: 19rem;
  margin-right: -40px;
  scroll-snap-type: y mandatory;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    height: 0px;
    width: 0px;
  }
}
.skeleton {
  min-height: 4.5rem;
}
.search {
  margin: 1rem 0;
}
