@use 'src/sass/variables' as *;
@use 'src/sass/extends';
@use 'src/sass/mixins';

.title {
  @extend %title--sm;
  color: white;
  margin-bottom: 1rem;
  text-align: center;
}
.content {
  margin-bottom: 1.5rem;
  p {
    @extend %body;
    color: $white85;
    margin-bottom: 1rem;
    b, strong {
      font-weight: bold;
    }
    &.small {
      color: $white65;
    }
  }
}