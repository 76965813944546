@use 'src/sass/variables' as *;
@use 'src/sass/mixins';

.container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  input {
    display: none;
  }
}
.checkbox {
  @include mixins.border();
  display: block;
  width: 20px;
  height: 20px;
  background-color: rgba($secondaryLighten, 0.1);
  border-radius: 4px;
  position: relative;
  transition: background-color 120ms $easeOutSine;
  &.checked {
    background-color: $primary;
  }
  svg {
    width: 16px;
    height: 16px;
    fill: white;
    position: absolute;
    top: 1px;
    left: 1px;
  }
}