@use 'src/sass/variables' as *;
@use 'src/sass/extends';
@use 'src/sass/mixins';

.container {
  align-items: center;
  background-color: $secondaryLight;
  border-radius: 0 0 1.5rem 1.5rem;
  display: flex;
  gap: 0.5rem;
  margin-bottom: 2.125rem;
  margin-top: -0.5rem;
  padding: 0.5rem 1rem 2rem;
  position: relative;
}
.content {
  display: flex;
  flex-direction: column;
  flex: auto;
  gap: 0.75rem;
}
.graphic {
  img {
    height: auto;
    object-fit: contain;
    width: 96px;
  }
}
.title {
  @extend %title;
  color: white;
  line-height: 1.2;
}
.meta {
  @extend %caption--sm;
  color: $white85;
  display: flex;
  gap: 1rem;
  b {
    @extend %caption--sm--bold;
  }
}
.footer {
  position: relative;
  text-align: center;
}
.rank {
  align-items: stretch;
  background-color: rgba($secondaryLighten, 0.2);
  border-radius: 0.5rem;
  display: flex;
  gap: 0.5rem;
  height: 40px;
  padding: 0.25rem 0.5rem;
  &_item {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    text-align: center;
  }
}
.divider {
  background-color: rgba($secondaryLighten, 0.4);
  width: 1px;
}
.label {
  @extend %caption--sm;
  color: $white85;
}
.value {
  @extend %caption--bold;
  color: white;
}
.tabs {
  bottom: -20px;
  left: 2rem;
  position: absolute;
  right: 2rem;
}
.loader {
  color: $white45;
  height: 64px;
  margin: 2rem auto 0;
  width: 64px;
  &_content {
    height: 64px;
    width: 64px;
  }
}
.button {
  align-items: center;
  display: flex;
  justify-content: center;
  button {
    background-color: $primary !important;
    transition: all 240ms $easeOutBack;
  }
  &.joining {
    button {
      width: 56px;
    }
    span:last-child {
      display: none;
    }
  }
}

// CSSTransition
.button {
  &.exit {
    span:last-child {
      display: none;
    }
    &Active {
      button {
        background-color: rgba($secondaryLighten, 0.2) !important;
      }
    }
  }
}
.rank {
  &.enter {
    background-color: transparent;
    height: 100%;
    position: absolute;
    top: 0;
    transform-origin: center;
    transform: scaleY(0);
    width: 100%;
    &Active {
      transform: scaleY(1);
      transition: all 240ms $easeOutBack;
    }
  }
}
.tabs {
  &.enter {
    transform-origin: center;
    transform: scaleY(0);
    &Active {
      transform: scaleY(1);
      transition: transform 300ms $easeOutBack;
    }
  }
}