@use 'src/sass/variables' as *;
@use 'src/sass/extends';
@use 'src/sass/mixins';

.content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-bottom: 0.125rem;
}
.title {
  @extend %headline;
  color: white;
  margin-bottom: 0.5rem;
  text-align: center;
}
.text {
  @extend %body;
  color: $white85;
  text-align: center;
  b {
    @extend %body--bold;
  }
}
.small {
  @extend %label;
  color: $white65;
  margin-bottom: 0.375rem;
}
.url {
  background-color: lighten($secondaryLight, 8%);
  border-radius: 0.5rem;
  border: 1px solid $secondaryLighten;
  padding: 0.5rem 0.75rem;
  position: relative;
  input {
    @include mixins.reset;
    color: white;
    font-family: $fontFamilyText;
    font-size: 0.9375rem;
    outline: none;
    width: calc(100% - 26px);
  }
}
.action {
  @include mixins.reset;
  align-items: center;
  cursor: pointer;
  display: flex;
  fill: white;
  height: 28px;
  justify-content: center;
  position: absolute;
  right: 0.5rem;
  top: 0.25rem;
  width: 28px;
}
.copied {
  @extend %caption--sm;
  color: $primary;
  position: absolute;
  right: 0;
  top: -21px;
  &.enter {
    opacity: 0;
    transform: translateY(50%);
    &Active {
      opacity: 1;
      transform: translateY(0);
      transition: transform 120ms $easeInQuad, opacity 120ms $easeInQuad;
    }
  }
  &.exit {
    opacity: 1;
    transform: translateY(0);
    &Active {
      opacity: 0;
      transform: translateY(-50%);
      transition: transform 120ms $easeInQuad, opacity 120ms $easeInQuad;
    }
  }
}