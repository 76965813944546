@use 'src/sass/variables' as *;
@use 'src/sass/extends';
@use 'src/sass/mixins';

.container {
  text-align: center;
}
.graphic {
  margin-top: 1.5rem;
  img {
    height: auto;
    max-width: 267px;
    width: 40%;
  }
}
.title {
  @extend %title;
  color: white;
}
.text {
  @extend %body;
  color: $white85;
  margin-top: 0.75rem;
}