@use 'src/sass/variables' as *;
@use 'src/sass/extends';
@use 'src/sass/mixins';

.container {
  background-color: $secondaryLight;
  border-radius: 0 0 1.5rem 1.5rem;
  margin-bottom: 0.5rem;
  margin-top: -1rem;
  padding: 0.5rem 1rem;
  position: relative;
  &.min {
    margin-bottom: 1.5rem;
    padding: 0.5rem 1rem 1.125rem;
  }
}
.content {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 0.125rem;
}
.meta {
  @extend %caption--sm;
  color: $white85;
  b {
    @extend %caption--sm--bold;
  }
}
.loader {
  color: $white45;
  height: 64px;
  margin: 2rem auto 0;
  width: 64px;
  &_content {
    height: 64px;
    width: 64px;
  }
}
.steps {
  max-width: 320px;
  min-width: 260px;
}
.help {
  @include mixins.reset;
  width: 24px;
  height: 24px;
  position: fixed;
  top: 0.75rem;
  right: 1rem;
  z-index: 8;
  background-color: $secondaryLighten;
  border-radius: 50%;
  color: white;
  font-weight: bold;
  font-size: 0.875rem;
  cursor: pointer;
  transition: background-color 200ms $easeOutSine;
  &:active {
    background-color: darken($secondaryLighten, 5%);
  }
}