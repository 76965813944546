@use 'src/sass/variables' as *;

.appbar {
  left: 0;
  margin: 0 auto;
  max-width: $maxWidth;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 8;
}
.main {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin-bottom: 96px;
  margin-top: 48px;
  padding: 0.5rem 0;
}
.navbar {
  bottom: 1.5rem;
  display: flex;
  justify-content: center;
  left: 1.5rem;
  position: fixed;
  right: 1.5rem;
  z-index: 2;
}