@use 'src/sass/variables' as *;
@use 'src/sass/mixins';

.container {
  height: 100%;
  inset: 0;
  position: fixed;
  width: 100%;
  z-index: 9;
}
.overlay {
  background-color: rgba(darken($secondary, 4), 0.86);
  height: 100%;
  inset: 0;
  position: absolute;
  width: 100%;
  z-index: 0;
}
.content {
  background: $secondaryLight;
  border-radius: 1.5rem 1.5rem 0 0;
  bottom: 0;
  left: 0;
  margin: auto;
  max-height: -moz-available;
  max-height: -webkit-fill-available;
  max-height: stretch;
  max-width: $maxWidth;
  padding: 1.5rem 1rem 1.75rem;
  position: absolute;
  right: 0;
  z-index: 1;
}
.action {
  @include mixins.reset;
  align-items: center;
  cursor: pointer;
  display: flex;
  fill: white;
  height: 40px;
  justify-content: center;
  position: absolute;
  right: -8px;
  top: -44px;
  width: 40px;
}
.body {
  height: auto;
}

// CSSTransition
.container {
  &.enter {
    .overlay {
      opacity: 0;
    }
    .content {
      transform: translateY(100%);
    }
    .action {
      opacity: 0;
    }
    &Active {
      .overlay {
        opacity: 1;
        transition: opacity 200ms $easeOutQuad;
      }
      .content {
        transform: translateY(0);
        transition: transform 240ms 120ms $easeOutQuad;
      }
      .action {
        opacity: 1;
        transition: opacity 240ms 120ms $easeOutQuad;
      }
    }
  }
  &.exit {
    .overlay {
      opacity: 1;
    }
    .content {
      transform: translateY(0);
    }
    .action {
      opacity: 1;
    }
    &Active {
      .overlay {
        opacity: 0;
        transition: opacity 200ms 120ms $easeInQuad;
      }
      .content {
        transform: translateY(100%);
        transition: transform 240ms $easeInQuad;
      }
      .action {
        opacity: 0;
        transition: opacity 240ms $easeInQuad;
      }
    }
  }
}