@use 'src/sass/variables' as *;
@use 'src/sass/extends';
@use 'src/sass/mixins';

.container {
  @include mixins.border();
  display: grid;
  grid-template-columns: 48px 3fr 1fr;
  align-items: center;
  background-color: darken($secondaryLight, 2%);
  background-image: url('../../assets/images/background_header.svg');
  background-position: top 12% right 4%;
  background-repeat: no-repeat;
  border-radius: 1rem;
  cursor: pointer;
  gap: 0.75rem;
  outline: 0 solid $primary;
  padding: 0.75rem 1rem 0.75rem 0.75rem;
  position: relative;
  scroll-snap-align: start;
  transition: outline-width 200ms $easeOutBack;
  user-select: none;
  &:active {
    background-color: darken($secondaryLight, 4%);
  }
  &.selected {
    outline-offset: -3px;
    outline-width: 3px;
  }
  &.disabled {
    cursor: not-allowed;
    background-color: darken($secondaryLight, 1%);
    border-color: rgba($secondaryLighten, 0.2);
    .name, .meta, .price, .price span {
      color: $white45;
      fill: $white45;
    }
    .logo {
      opacity: 0.45;
      img {
        filter: grayscale(100%);
      }
    }
  }
}
.content {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  overflow: hidden;
}
.name {
  @extend %headline--sm;
  color: white;
  overflow: hidden;
  text-wrap: nowrap;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.meta {
  @extend %caption;
  color: $white85;
  overflow: hidden;
  text-wrap: nowrap;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.price {
  @extend %caption--sm;
  align-items: center;
  color: $white85;
  display: inline-flex;
  flex-wrap: nowrap;
  gap: 0.25rem;
  fill: white;
  span {
    @extend %caption--lg--bold;
    color: white;
    text-wrap: nowrap;
    white-space: nowrap;
  }
  svg {
    height: 20px;
    width: 20px;
  }
}
.avatar {
  position: relative;
  width: 48px;
  height: 48px;
}
.logo {
  @include mixins.border();
  align-items: center;
  background-color: lighten($secondaryLight, 12%);
  border-radius: 50%;
  width: 48px;
  display: flex;
  height: 48px;
  justify-content: center;
  overflow: hidden;
  img {
    display: block;
    max-height: 38px;
    max-width: 38px;
    object-fit: contain;
  }
  svg {
    fill: rgba($secondaryLighten, 0.6);
    height: auto;
    width: 24px;
  }
}
.badge {
  bottom: -2px;
  height: 20px;
  position: absolute;
  transform-origin: center;
  width: 20px;
  svg {
    height: 100%;
    object-fit: contain;
    width: 100%;
  }
  &.enter {
    transform: scale(0);
    &Active {
      transform: scale(1);
      transition: transform 240ms $easeOutBack;
    }
  }
  &.exit {
    transform: scale(1);
    &Active {
      transform: scale(0);
      transition: transform 240ms $easeOutQuad;
    }
  }
}