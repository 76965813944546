@use 'src/sass/variables' as *;
@use 'src/sass/extends';
@use 'src/sass/mixins';

.title {
  @extend %title--sm;
  color: white;
  margin-bottom: 1rem;
  text-align: center;
}
.content {
  p {
    @extend %body;
    color: $white85;
    margin-bottom: 1rem;
    b, strong {
      font-weight: bold;
    }
    &.small {
      color: $white65;
    }
  }
}
.link {
  margin-top: 1.5rem;
  text-align: right;
  span {
    @extend %body--bold;
    color: $primary;
    cursor: pointer;
    transition: color 200ms $easeOutQuart;
    &:hover, &:focus {
      color: lighten($primary, 10%);
    }
    &:active {
      color: darken($primary, 10%);
    }
  }
}
