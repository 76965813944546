.container {
  position: relative;
}
.crop {
  overflow: hidden;
}
.content {
  position: relative;
  padding-bottom: 40px;
  overflow-x: scroll;
  overscroll-behavior-x: contain;
  scroll-snap-type: x mandatory;
  white-space: nowrap;
}
.items {
  width: 100%;
}
.item {
  position: relative;
  display: inline-block;
  scroll-snap-align: start;
  margin-right: 1rem;
  &:last-child {
    margin-right: 0;
  }
}