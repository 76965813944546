@use 'src/sass/variables' as *;
@use 'src/sass/extends';
@use 'src/sass/mixins';

.content {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.positions {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}
.header {
  margin-bottom: -0.5rem;
}
.title {
  @extend %body--bold;
  color: white;
  margin-bottom: 0.25rem;
}
.text {
  @extend %caption;
  color: $white65;
}
.subtitle {
  @extend %caption--bold;
  color: $white85;
}