@use 'src/sass/variables' as *;
@use 'src/sass/extends';
@use 'src/sass/mixins';

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
}
.title {
  @extend %title--sm;
  color: white;
  margin-bottom: 0.75rem;
  text-align: center;
  span {
    color: $primary;
  }
}
.astros {
  @extend %caption--sm;
  align-items: center;
  color: $white85;
  display: inline-flex;
  gap: 0.25rem;
  span {
    @extend %caption--bold;
    color: white;
  }
  background-color: rgba($secondaryLighten, 0.2);
  border-radius: 0.5rem;
  fill: white;
  padding: 0.25rem 0.5rem;
}