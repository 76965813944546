@use 'src/sass/variables' as *;
@use 'src/sass/mixins';
@use 'src/sass/extends';

.container {
  padding: 1.5rem 1rem;
  margin-top: -8px;
  background: linear-gradient(180deg, #24263D 20%, #181928 100%);
}
.content {
  text-align: left;
  h2 {
    @extend %headline;
    color: white;
    margin-bottom: 1rem;
  }
  p {
    @extend %body;
    color: $white85;
    line-height: 1.6;
    margin-bottom: 1.5rem;
  }
  i {
    font-style: italic;
  }
  b {
    @extend %body--bold;
  }
}
.prizes {
  @include mixins.border();
  color: white;
  background-color: $secondaryLight;
  border-radius: 1rem;
  padding: 1rem;

  ul {
    padding-left: 1rem;
  }
  li {
    padding-left: 0.25rem;
    @extend %body--lg;
    color: $white85;
    margin-bottom: 1rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.heading {
  @extend %caption--lg--bold;
  margin-bottom: 0.25rem;
}
.description {
  @extend %body;
  color: $white85;
  b {
    @extend %body--bold;
  }
}
.sponsors {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
}
.sponsor {
  width: 150px;
  height: 100px;
  flex: 0 0 45%;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
 .footer {
  margin-top: 2rem;
  text-align: center;
 }
 .logo {
  width: 180px;
  fill: white;
  margin: 1rem auto;
 }