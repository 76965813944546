@use 'src/sass/variables' as *;
@use 'src/sass/mixins';
@use 'src/sass/extends';

.lives {
  @extend %caption;
  align-items: center;
  background-color: $secondaryLight;
  border-radius: 0.5rem;
  color: white;
  display: flex;
  gap: 0.5rem;
  padding: 0.375rem 1rem;
  b {
    @extend %caption--bold;
  }  
}
.iframe {
  background-color: #111;
  border-radius: 1.5rem;
  height: 56.25vw;
  margin: 0 -1rem 0 -1rem;
  max-height: 293px;
  overflow: hidden;
  width: calc(100% + 2rem);
  iframe {
    height: 100%;
    width: 100%;
  }
}
.skeleton {
  border-radius: 1.5rem;
  height: 56.25vw;
  margin: 0 -1rem 0 -1rem;
  max-height: 293px;
  width: calc(100% + 2rem);
}
.button {
  @include mixins.reset;
  align-items: center;
  background-color: $primaryDark;
  border-radius: 50%;
  border: 1px solid $primary;
  cursor: pointer;
  display: flex;
  fill: white;
  height: 24px;
  justify-content: center;
  transition: background-color 120ms $easeOutSine;
  width: 24px;
  &:active {
    background-color: $primaryDarken;
  }
}
.referral {
  @include mixins.border();
  background-color: $secondaryLight;
  padding: 0.75rem 1rem;
  border-radius: 1rem;
}