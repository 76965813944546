@use 'src/sass/variables' as *;
@use 'src/sass/extends';
@use 'src/sass/mixins';

.container {
  background-color: #4FB58E;
  background-image: url('../../assets/images/background_field.png');
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 0.5rem;
  height: calc(296px * 1.67);
  margin: 0 auto;
  position: relative;
  width: 296px;
}
.content {
  align-items: center;
  display: flex;
  flex-direction: column-reverse;
  height: 100%;
  justify-content: space-between;
  padding: 10% 4%;
  width: 100%;
}
.row {
  align-items: center;
  display: flex;
  gap: 0.25rem;
  justify-content: space-around;
  width: 100%;
}
@media (min-width: 375px) {
  .container {
    height: calc(327px * 1.67);
    width: 327px;
  }
  .row {
    gap: 0.125rem;
  }
}
@media (min-width: 390px) {
  .container {
    height: calc(342px * 1.67);
    width: 342px;
  }
}
@media (min-width: 410px) {
  .container {
    height: calc(380px * 1.67);
    width: 380px;
  }
}