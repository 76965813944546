@use 'src/sass/variables' as *;
@use 'src/sass/extends';
@use 'src/sass/mixins';

.content {
  display: grid;
  grid-gap: 1.5rem;
  grid-template-columns: 1fr 1fr;
}
.title {
  @extend %body--bold;
  color: white;
  margin-bottom: -0.5rem;
}
.empty {
  margin-top: 2rem;
  text-align: center;
  h3 {
    @extend %title;
    color: $white85;
  }
  p {
    @extend %body;
    color: $white65;
    margin-top: 0.75rem;
  }
}
.option {
  @include mixins.border();
  background-color: $secondaryLight;
  border-radius: 1rem;
  cursor: pointer;
  outline: 0 solid $primary;
  padding: 1rem;
  transition: outline-width 200ms $easeOutBack;
  &__content {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  &.selected {
    outline-offset: -2px;
    outline-width: 4px;
  }
}
.label {
  @extend %caption--bold;
  color: white;
  line-height: 1;
  padding-top: 0.125rem;
  text-align: center;
}
.row {
  display: flex;
  gap: 0.5rem;
  justify-content: space-around;
}
.shirt {
  align-items: center;
  display: flex;
  height: 16px;
  justify-self: center;
  width: 16px;
  svg {
    height: 100%;
    width: 100%;
  }
}

@media (min-width: 375px) {
  .option {
    &__content {
      gap: 0.75rem;
    }
  }
  .row {
    gap: 0.75rem;
  }
}