// COLORS
$white85: rgba(255, 255, 255, 0.85);
$white65: rgba(255, 255, 255, 0.65);
$white45: rgba(255, 255, 255, 0.45);
$white30: rgba(255, 255, 255, 0.30);
$black85: rgba(0, 0, 0, 0.85);
$black65: rgba(0, 0, 0, 0.65);
$black45: rgba(0, 0, 0, 0.45);
$black25: rgba(0, 0, 0, 0.25);

$primary: #FD2E6A;
$primaryDark: #D32254;
$primaryDarken: #A51D44;
$secondary: #181928;
$secondaryLight: #24263D;
$secondaryLighten: #8F92BC;
$background: #181928;
$black: #21231E;
$error: #E84749;
$warning: #E89A3C;
$success: #6ABE39;
$gradient: linear-gradient(190deg, #FD2E6A -10%, #D32254 37.33%, #A51D44 110%);
$skyBlue: #269BDC;
$midnightBlue: #222232;

// FONTS
$fontFamilyDisplay: 'Inter', sans-serif;
$fontFamilyCaption: 'Poppins', sans-serif;
$fontFamilyText: system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

// LAYOUT
$breakpoints: (
  sm: 272px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px
);
$maxWidth: 520px;

// ANIMATIONS
$easeInSine: cubic-bezier(0.47, 0, 0.745, 0.715);
$easeOutSine: cubic-bezier(0.39, 0.575, 0.565, 1);
$easeInOutSine: cubic-bezier(0.445, 0.05, 0.55, 0.95);
$easeInQuad: cubic-bezier(0.55, 0.085, 0.68, 0.53);
$easeOutQuad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
$easeInOutQuad: cubic-bezier(0.455, 0.03, 0.515, 0.955);
$easeInCubic: cubic-bezier(0.55, 0.055, 0.675, 0.19);
$easeOutCubic: cubic-bezier(0.215, 0.61, 0.355, 1);
$easeInOutCubic: cubic-bezier(0.645, 0.045, 0.355, 1);
$easeInQuart: cubic-bezier(0.895, 0.03, 0.685, 0.22);
$easeOutQuart: cubic-bezier(0.165, 0.84, 0.44, 1);
$easeInOutQuart: cubic-bezier(0.77, 0, 0.175, 1);
$easeInQuint: cubic-bezier(0.755, 0.05, 0.855, 0.06);
$easeOutQuint: cubic-bezier(0.23, 1, 0.32, 1);
$easeInOutQuint: cubic-bezier(0.86, 0, 0.07, 1);
$easeInExpo: cubic-bezier(0.95, 0.05, 0.795, 0.035);
$easeOutExpo: cubic-bezier(0.19, 1, 0.22, 1);
$easeInOutExpo: cubic-bezier(1, 0, 0, 1);
$easeInBack: cubic-bezier(0.6, -0.28, 0.735, 0.045);
$easeOutBack: cubic-bezier(0.175, 0.885, 0.32, 1.275);
$easeInOutBack: cubic-bezier(0.68, -0.55, 0.265, 1.55);
