@use 'src/sass/variables' as *;
@use 'src/sass/mixins';

.container {
  align-items: center;
  backdrop-filter: saturate(160%) blur(16px);
  background-color: rgba($secondaryLighten, 0.24);
  border-radius: 1rem;
  display: flex;
  height: 50px;
  justify-content: center;
  &.secondary {
    display: none;
  }
}
.item {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 48px;
  justify-content: center;
  width: 75px;
}
.label {
  color: $white65;
  font-family: $fontFamilyCaption;
  font-size: 10px;
  font-weight: 600;
  transition: color 200ms $easeOutQuad;
}
.icon {
  align-items: center;
  display: flex;
  fill: $white65;
  height: 24px;
  justify-content: center;
  margin-bottom: 0.25rem;
  position: relative;
  transition: all 200ms $easeOutQuad;
  width: 24px;
  svg {
    height: 24px;
    position: relative;
    width: 24px;
    z-index: 1;
  }
  &::after {
    background-color: $primary;
    border-radius: 50%;
    box-shadow: 0 9px 12px 1px rgba($primary, 0.16), 0 3px 16px 2px rgba($primary, 0.14), 0 5px 6px -3px rgba($primary, 0.22);
    content: '';
    display: block;
    height: 40px;
    position: absolute;
    transform: scale(0);
    width: 40px;
    z-index: 0;
  }
}
.item {
  &.isCurrent {
    .label {
      color: $primary;
    }
    .icon {
      fill: white;
      transform: translateY(-60%);
      &::after {
        transform: scale(1);
        transition: transform 240ms $easeOutBack;
      }
    }
  }
}
