@use 'src/sass/variables' as *;
@use 'src/sass/extends';
@use 'src/sass/mixins';

.container {
  @include mixins.border();
  align-items: center;
  background-color: $secondaryLight;
  background-image: url('../../assets/images/background_header.svg');
  background-position: top 12% right 4%;
  background-repeat: no-repeat;
  border-radius: 1rem;
  cursor: pointer;
  display: flex;
  gap: 0.75rem;
  justify-content: space-between;
  padding: 0.625rem 1rem;
  position: relative;
  transition: background-color 120ms $easeOutSine;
  user-select: none;
  scroll-snap-align: start;
  &:active {
    background-color: darken($secondaryLight, 2%);
  }
  &.current {
    outline: 2px solid $primary;
    outline-offset: -2px;
    &.disabled {
      outline-color: $primaryDark;
    }
  }
  &.disabled {
    background-color: rgba($secondaryLight, 0.5);
    background-image: none;
    border-color: $secondaryLight;
    pointer-events: none;
    .content, .score, .position {
      opacity: 0.45;
    }
    .avatar {
      opacity: 0.65;
    }
  }
  &.main {
    @include mixins.border('primary');
    padding: 0.625rem 0.5rem 0.625rem 1rem;
    background-color: $primaryDark;
    &:active {
      background-color: darken($primaryDark, 4%);
    }
    .avatar {
      border-color: lighten($primary, 16%);
      svg {
        fill: lighten($primary, 24%);
      }
    }
    .score {
      gap: 0.125rem;
    }
  }
}
.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 0.25rem;
  overflow: hidden;
}
.team {
  @extend %headline--sm;
  width: 100%;
  color: white;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.name {
  @extend %body;
  color: $white85;
}
.score {
  display: flex;
  text-align: right;
  svg {
    fill: white;
  }
}
.label {
  @extend %caption--sm;
  color: $white85;
}
.value {
  @extend %caption--lg--bold;
  color: white;
}
.position {
  @extend %caption--sm--bold;
  color: white;
  direction: rtl;
  flex: 0 0 12px;
  max-width: 12px;
  text-align: left;
}
.graphic {
  height: 40px;
  position: relative;
  width: 40px;
}
.avatar {
  @include mixins.border();
  align-items: center;
  border-radius: 50%;
  display: flex;
  flex: 0 0 40px;
  height: 40px;
  justify-content: center;
  max-width: 40px;
  overflow: hidden;
  width: 40px;
  img {
    display: block;
    height: 100%;
    object-fit: contain;
    width: 100%;
  }
  svg {
    fill: rgba($secondaryLighten, 0.8);
    height: 22px;
    width: 20px;
  }
}
%medal {
  @extend %caption--sm--bold;
  align-items: center;
  background: linear-gradient(to bottom right, #d1d7da 50%, darken(#d1d7da, 5%) 50%);
  border-radius: 50%;
  border: 1.5px solid lighten(adjust-hue(#d1d7da, 10), 10%);
  bottom: -10px;
  box-shadow: inset 0 0 0 darken(#d1d7da, 15%), 2px 2px 0 rgba(0, 0, 0, 0.08);
  color: white;
  content: '';
  display: flex;
  font-size: 11px;
  height: 20px;
  justify-content: center;
  position: absolute;
  text-align: center;
  text-shadow: 2px 2px 0 darken(#d1d7da, 20%);
  width: 20px;
}
.container:not(.main) {
  &:nth-child(1) {
    .avatar {
      &::after {
        @extend %medal;
        $gold-medal: #f9ad0e;
        background: linear-gradient(to bottom right, $gold-medal 50%, darken($gold-medal, 5%) 50%);
        border-color: lighten(adjust-hue($gold-medal, 10), 10%);
        box-shadow: inset 0 0 0 darken($gold-medal, 15%), 2px 2px 0 rgba(0, 0, 0, 0.08);
        content: '1';
        text-shadow: 0 0 4px darken($gold-medal, 20%);
      }
    }
  }
  &:nth-child(2) {
    .avatar {
      &::after {
        @extend %medal;
        $silver-medal: #d1d7da;
        background: linear-gradient(to bottom right, $silver-medal 50%, darken($silver-medal, 5%) 50%);
        border-color: lighten(adjust-hue($silver-medal, 10), 10%);
        box-shadow: inset 0 0 0 darken($silver-medal, 15%), 2px 2px 0 rgba(0, 0, 0, 0.08);
        content: '2';
        text-shadow: 0px 0px 4px darken($silver-medal, 40%);
      }
    }
  }
  &:nth-child(3) {
    .avatar {
      &::after {
        @extend %medal;
        $bronze-medal: #df7e08;
        background: linear-gradient(to bottom right, $bronze-medal 50%, darken($bronze-medal, 5%) 50%);
        border-color: lighten(adjust-hue($bronze-medal, 10), 10%);
        box-shadow: inset 0 0 0 darken($bronze-medal, 15%), 2px 2px 0 rgba(0, 0, 0, 0.08);
        content: '3';
        text-shadow: 0 0 4px darken($bronze-medal, 20%);
      }
    }
  }
  &:nth-child(4) {
    .avatar {
      &::after {
        @extend %medal;
        $bronze-medal: #aebf13;
        background: linear-gradient(to bottom right, $bronze-medal 50%, darken($bronze-medal, 5%) 50%);
        border-color: lighten(adjust-hue($bronze-medal, 10), 10%);
        box-shadow: inset 0 0 0 darken($bronze-medal, 15%), 2px 2px 0 rgba(0, 0, 0, 0.08);
        content: '4';
        text-shadow: 0 0 4px darken($bronze-medal, 20%);
      }
    }
  }
}