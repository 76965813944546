@use 'src/sass/variables' as *;
@use 'src/sass/extends';
@use 'src/sass/mixins';

.container {
  width: 100%;
  max-width: $maxWidth;
  margin: 0 auto;
  position: fixed;
  overflow-x: hidden;
  background-color: $secondary;
  z-index: 8;
  inset: 0;
}
.header {
  position: absolute;
  right: 0;
  z-index: 2;
}
.link {
  @extend %caption--bold;
  @include mixins.reset;
  color: $white85;
  cursor: pointer;
  padding: 1rem;
  transition: color 200ms $easeOutSine;
  &:hover, &:focus {
    color: white;
  }
}
.content {
  inset: 0;
  position: absolute;
  z-index: 1;
}
.footer {
  bottom: 0;
  left: 0;
  margin: auto;
  padding: 1rem;
  position: absolute;
  right: 0;
  text-align: center;
  z-index: 2;
}
.button {
  margin-top: 1rem;
  button {
    box-shadow: 0 9px 12px 1px rgba($primary, 0.12), 0 3px 16px 2px rgba($primary, 0.1), 0 5px 6px -3px rgba($primary, 0.18);
  }
}

// CSSTransition
.container {
  &.enter {
    transform: translateX(100%);
    &Active {
      transform: translateX(0);
      transition: transform 480ms $easeInQuad;
    }
  }
  &.exit {
    transform: translateX(0);
    &Active {
      transform: translateX(-50%);
      transition: transform 480ms $easeInQuad;
    }
  }
}