@use 'src/sass/variables' as *;

.container {
  height: 100vh;
  max-height: -moz-available;
  max-height: -webkit-fill-available;
  max-height: stretch;
  position: relative;
  width: 100%;
}
.content {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}
.loader {
  height: 140px;
  width: 140px;
}