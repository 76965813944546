@use 'src/sass/variables' as *;
@use 'src/sass/extends';
@use 'src/sass/mixins';

.container {
  @include mixins.border();
  background-color: $secondaryLight;
  background-image: url('../../assets/images/background_card.svg');
  background-position: 50% -160%;
  background-repeat: no-repeat;
  border-radius: 1rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.75rem 1rem;
  position: relative;
  transition: background-color 120ms $easeOutSine;
  user-select: none;
  &:active {
    background-color: darken($secondaryLight, 2%);
  }
}
.title {
  @extend %headline--bold;
  color: white;
}
.date {
  @extend %body;
  color: $white85;
  b {
    @extend %body--bold;
  }
}
.footer {
  @extend %caption--sm;
  align-items: center;
  color: $white85;
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  b {
    @extend %caption--sm--bold;
  }
}
.rank {
  display: inline-flex;
  gap: 0.75rem;
  &_item {
    display: flex;
    flex-direction: column;
    text-align: right
  }
  .rank_item {
    text-align: left
  }
}
.label {
  @extend %caption--sm;
  color: $white65;
}
.value {
  @extend %caption--lg--bold;
  color: white;
}
.cta {
  @extend %caption--bold;
  color: $primary;
}
.na {
  @extend %caption--bold;
  color: $white65;
}
.icon {
  display: none;
  height: 24px;
  position: absolute;
  right: 0.5rem;
  top: calc(50% - 12px);
  width: 24px;
  svg {
    fill: $secondaryLighten;
    height: 100%;
    width: 100%;
  }
}
.status {
  position: absolute;
  right: 1rem;
}