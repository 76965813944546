@use 'src/sass/variables' as *;
@use 'src/sass/extends';
@use 'src/sass/mixins';

// CSSTransition
.content {
  &.enter {
    transform: translateX(100%);
    &Active {
      transform: translateX(0);
      transition: transform 240ms $easeOutBack;
    }
  }
  &.exit {
    transform: translateX(0);
    &Active {
      transform: translateX(-100%);
      transition: transform 240ms $easeInBack;
    }
  }
}