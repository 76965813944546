@use 'src/sass/variables' as *;
@use 'src/sass/mixins';
@use 'src/sass/extends';

.link {
  @extend %body--bold;
  color: $primary;
  cursor: pointer;
  transition: color 200ms $easeOutQuart;
  &:hover, &:focus {
    color: lighten($primary, 10%);
  }
  &:active {
    color: darken($primary, 10%);
  }
}
.policies {
  @extend %body;
  color: $white85;
  margin-bottom: 1.5rem;
}
.recoverPassword {
  margin-bottom: 1.5rem;
  text-align: right;
}