@use 'src/sass/variables' as *;
@use 'src/sass/extends';
@use 'src/sass/mixins';

.heading {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.title {
  @extend %headline;
  color: white;
}
.description {
  @extend %body;
  color: $white85;
  margin-top: 0.75rem;
}
.display {
  @extend %title;
  color: $primary;
}