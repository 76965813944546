@use 'src/sass/variables' as *;
@use 'src/sass/extends';

.container {
  height: 100%;
  position: relative;
}
.image {
  left: 0;
  margin: 0 auto;
  max-width: calc($maxWidth / 2.25);
  position: absolute;
  right: 0;
  top: 10%;
  width: 48%;
  img {
    animation-delay: 400ms;
    animation-duration: 2000ms;
    animation-fill-mode: backwards;
    animation-iteration-count: infinite;
    animation-name: animate-bounce;
    animation-timing-function: $easeInOutSine;
    height: 100%;
    object-fit: contain;
    object-position: bottom;
    transform-origin: bottom;
    width: 100%;
  }
}
.content {
  bottom: 104px;
  color: white;
  padding: 0.5rem 1rem;
  position: absolute;
  z-index: 1;
}
.title {
  @extend %title--lg;
  color: white;
  font-size: 1.5rem;
  text-align: center
}
.text {
  @extend %body;
  color: $white85;
  margin-top: 0.75rem;
}

// CSSTransition
.container {
  &.enter {
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    .image {
      opacity: 0;
      transform: translateX(100%);
    }
    .content {
      transform: translateX(100%);
    }
    &Active {
      .image {
        opacity: 1;
        transform: translateX(0);
        transition: transform 600ms 100ms $easeInBack, opacity 600ms 100ms $easeInBack;
      }
      .content {
        transform: translateX(0);
        transition: transform 600ms $easeInQuart;
      }
    }
  }
  &.exit {
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(0);
    &Active {
      transform: translateX(-100%);
      transition: transform 600ms $easeInQuart;
    }
  }
}

// Animations
@keyframes animate-bounce {
  0% { transform: translateY(-6%);}
  50% { transform: translateY(6%);}
  100% { transform: translateY(-6%);}
}