@use 'src/sass/variables' as *;
@use 'src/sass/extends';
@use 'src/sass/mixins';

.container {
  width: 110px;
  position: relative;
}
.content {
  padding-bottom: 0.5rem;
  cursor: pointer;
}
.label {
  @extend %label;
  color: $white65;
}
.value {
  @extend %caption--bold;
  color: $white85;
  display: flex;
  align-items: center;
  white-space: nowrap;
  span {
    display: block;
    width: 24px;
    height: 24px;
    svg {
      width: 24px;
      height: 24px;
      fill: currentColor;
    }
  }
}
.dropdown {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  background-color: lighten($secondaryLight, 8%);
  border-radius: 0.75rem;
  border: 1px solid $secondaryLighten;
  padding: 0.5rem;
  position: absolute;
  z-index: 3;
  left: 0;
  top: 90%;
}
.option {
  @extend %caption;
  color: $white85;
  white-space: nowrap;
  padding: 0.375rem 0.5rem;
  transition: color 200ms $easeOutSine;
  cursor: pointer;
  &:hover, &:active {
    color: white;
  }
  &.selected {
    @extend %caption--bold;
    color: white;
  }
}

.dropdown {
  &.enter {
    opacity: 0;
    transform: scaleY(0.75);
    transform-origin: top;
    &Active {
      opacity: 1;
      transform: scaleY(1);
      transition: opacity 200ms $easeOutSine, transform 120ms $easeOutSine;
    }
  }
  &.exit {
    opacity: 1;
    transform: scaleY(1);
    transform-origin: top;
    &Active {
      opacity: 0;
      transform: scaleY(0.75);
      transition: opacity 120ms $easeInSine, transform 200ms $easeInSine;
    }
  }
}