@use 'src/sass/variables' as *;
@use 'src/sass/extends';
@use 'src/sass/mixins';

.container {
  margin-bottom: 1.5rem;
  &.hasError {
    .input {
      border-color: $error;
    }
  }
}
.content {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}
.marginOffset {
  background-color: rgba($color: red, $alpha: 0.12);
  margin-bottom: -1.5rem;
}
.label {
  @extend %label;
  color: $white85;
  display: block;
}
.input {
  @include mixins.border();
  background-color: rgba($secondaryLighten, 0.1);
  border-radius: 8px;
  height: 40px;
  padding: 0.5rem 0.75rem;
  position: relative;
  transition: border-color 120ms $easeOutSine;
  width: 100%;
  input {
    @include mixins.reset;
    @extend %body;
    color: white;
    height: 100%;
    outline: none;
    width: 100%;
    &::placeholder {
      color: rgba($secondaryLighten, 0.65);
    }
    &[type='search'] {
      padding-right: 2.875em;
      &::-webkit-search-cancel-button {
        display: none;
      }
      &:not(:placeholder-shown) ~ .clearIcon {
        visibility: visible;
      }
    }
  }
}
.feedback {
  display: flex;
  flex-wrap: nowrap;
}
.bounds {
  height: 1.25rem;
  width: 0;
}
.error {
  @extend %caption--sm;
  color: $error;
  line-height: 1.1;
  padding-bottom: 0.25rem;
  width: 100%;
}
.showPassword {
  align-items: center;
  cursor: pointer;
  display: flex;
  fill: $secondaryLighten;
  height: 40px;
  justify-content: center;
  position: absolute;
  right: 0.125rem;
  top: calc(50% - 20px);
  width: 40px;
}
.searchIcon {
  align-items: center;
  display: flex;
  fill: $white85;
  height: 24px;
  justify-content: center;
  pointer-events: none;
  position: absolute;
  right: 0.375rem;
  top: calc(50% - 12px);
  width: 24px;
  svg {
    height: 20px;
    width: 20px;
  }
}
.clearIcon {
  align-items: center;
  display: flex;
  fill: white;
  justify-content: center;
  position: absolute;
  right: 2rem;
  top: calc(50% - 9px);
  width: 18px;
  height: 18px;
  background-color: rgba($secondaryLighten, 0.6);
  border-radius: 50%;
  visibility: hidden;
  cursor: pointer;
  svg {
    height: 14px;
    width: 14px;
  }
}