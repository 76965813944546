@use 'src/sass/variables' as *;
@use 'src/sass/mixins';
@use 'src/sass/extends';

.container {
  padding: 1.5rem 0;
  background-image: url('../../assets/images/background_auth.svg');
  background-repeat: no-repeat;
  background-size: contain;
  min-height: 100svh;
}
.header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.5rem;
}
.logo {
  fill: white;
  width: 224px;
}
.content {
  background-color: white;
  border-radius: 8px 8px 0 0;
  padding: 1rem;
  height: 100%;
  text-align: justify;
  h1 {
    font-size: 1rem;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 1rem;
  }
  h2 {
    @extend %body--bold;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 1rem;
  }
  p {
    @extend %body;
    color: $black85;
    margin-bottom: 1rem;
    &:last-child {
      text-align: center;
    }
  }
  i {
    font-style: italic;
  }
  b {
    @extend %body--bold;
  }
  ul {
    padding-left: 1rem;
    list-style: lower-roman;
  }
  li {
    padding-left: 0.5rem;
    @extend %body;
    color: $black85;
    margin-bottom: 1rem;
  }
}