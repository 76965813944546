@use 'src/sass/variables' as *;
@use 'src/sass/mixins';

.container {
  animation: animation 1200ms $easeInOutSine infinite;
  background-color: rgba($secondaryLighten, $alpha: 0.16);
  border-radius: 1rem;
  min-height: 158px;
}

@keyframes animation {
  0%   { opacity:1; }
  50%  { opacity:0.5; }
  100% { opacity:1; }
}