@import 'src/sass/variables';

%body {
  font-family: $fontFamilyCaption;
  font-size: 0.875rem; // 14px
  font-weight: 400;
  line-height: 1.4;
  &--bold {
    @extend %body;
    font-weight: 600;
  }
  &--lg {
    @extend %body;
    font-size: 1rem; // 16px
  }
}

%title {
  font-family: $fontFamilyCaption;
  font-size: 1.25rem; // 20px
  font-weight: 700;
  line-height: 1.4;
  &--lg {
    @extend %title;
    font-size: 1.375rem; // 22px
  }
  &--sm {
    @extend %title;
    font-size: 1.125rem;
  }
}

%headline {
  font-family: $fontFamilyDisplay;
  font-size: 1.125rem; // 18px
  font-weight: 700;
  line-height: 1.3;
  &--bold {
    @extend %headline;
    font-weight: 800;
  }
  &--sm {
    @extend %headline;
    font-size: 1rem;
  }
}

%caption {
  font-family: $fontFamilyCaption;
  font-size: 0.8125rem; // 13px
  font-weight: 400;
  line-height: 1.3;
  &--bold {
    @extend %caption;
    font-weight: 700;
  }
  &--sm {
    @extend %caption;
    font-size: 0.75rem; // 12px
    &--bold {
      @extend %caption--sm;
      font-weight: 700;
    }
  }
  &--lg {
    @extend %caption;
    font-size: 1rem;
    &--bold {
      @extend %caption--lg;
      font-weight: 700;
    }
  }
}

%label {
  font-family: $fontFamilyDisplay;
  font-size: 0.75rem;
}