@use 'src/sass/variables' as *;
@use 'src/sass/extends';
@use 'src/sass/mixins';

.container {
  left: 0;
  margin: auto;
  max-width: $maxWidth;
  padding: 0.75rem;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 10;
}
.content {
  background-color: $secondary;
  border-radius: 0.5rem;
  color: white;
  display: flex;
  gap: 0.75rem;
  padding: 0.75rem 1rem;
}
.icon {
  fill: white;
}
.title {
  @extend %body--bold;
  margin-bottom: 0.125rem;
}
.message {
  @extend %caption--sm;
}
.error {
  .content {
    background-color: $error;
  }
}
.success {
  .content {
    background-color: $success;
  }
}

// CSSTransition
.container {
  &.enter {
    .content {
      transform: translateY(-100%);
    }
    &Active {
      .content {
        transform: translateY(0);
        transition: transform 240ms $easeOutQuad;
      }
    }
  }
  &.exit {
    .content {
      transform: translateY(0);
    }
    &Active {
      .content {
        transform: translateY(-100%);
        transition: transform 240ms $easeInQuad;
      }
    }
  }
}