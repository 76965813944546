@use 'src/sass/variables' as *;
@use 'src/sass/extends';
@use 'src/sass/mixins';

.empty {
  text-align: center;
}
.graphic {
  margin-top: 1.5rem;
  img {
    height: auto;
    max-width: 267px;
    width: 40%;
  }
}
.text {
  @extend %body;
  color: $white65;
  margin-top: 0.75rem;
}
.empty {
  margin-top: 2rem;
  text-align: center;
  h3 {
    @extend %title;
    color: $white85;
  }
  p {
    @extend %body;
    color: $white65;
    margin-top: 0.75rem;
  }
}