@use 'src/sass/variables' as *;
@use 'src/sass/extends';
@use 'src/sass/mixins';

.container {
  background-color: $secondaryLight;
  border-radius: 0 0 1.5rem 1.5rem;
  margin-bottom: 1rem;
  margin-top: -0.5rem;
  padding: 0.25rem 1rem 1rem;
  position: relative;
}
.content {
  align-items: center;
  display: flex;
  gap: 1.25rem;
}
.picture {
  flex: 0 0 96px;
  position: relative;
}
.avatar {
  align-items: center;
  background-color: lighten($secondaryLight, 8%);
  border-radius: 50%;
  display: flex;
  height: 96px;
  justify-content: center;
  margin: 0 auto;
  overflow: hidden;
  width: 96px;
  img {
    display: block;
    height: 100%;
    object-fit: contain;
    width: 100%;
  }
  svg {
    fill: rgba($secondaryLighten, 0.4);
    height: 5rem;
    width: 5rem;
  }
}
.camera {
  @include mixins.reset;
  align-items: center;
  background-color: $primary;
  border-radius: 50%;
  bottom: 0;
  cursor: pointer;
  display: flex;
  height: 28px;
  justify-content: center;
  left: 0;
  position: absolute;
  width: 28px;
  svg {
    fill: white;
    height: 16px;
    width: 16px;
  }
}
.body {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 0.375rem;
}
.title {
  @extend %title--sm;
  color: white;
}
.team {
  span {
    @extend %caption--bold;
    background-color: $primaryDark;
    border-radius: 18px;
    color: white;
    display: inline-block;
    padding: 0.25rem 0.75rem;
  }
  em {
    @extend %caption;
    background-color: lighten($secondaryLight, 8%);
    border-radius: 18px;
    color: $white65;
    display: inline-block;
    padding: 0.25rem 0.75rem;
  }
}
.input {
  display: none;
}