@use 'src/sass/variables' as *;
@use 'src/sass/extends';
@use 'src/sass/mixins';

.container {
  height: calc(100vh - 6.5rem);
  height: calc(100svh - 6.5rem);
}
.tabs {
  margin-bottom: 1rem;
}
.content {
  height: 100%;
  overflow-y: auto;
  padding-bottom: 4rem;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}