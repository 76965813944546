@use 'src/sass/variables' as *;
@use 'src/sass/extends';
@use 'src/sass/mixins';

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: -0.75rem;
}
.formation, .total {
  @extend %caption--sm;
  color: $white65;
  flex: 1;
  span {
    @extend %caption--bold;
    color: white;
  }
}
.total {
  text-align: center;
  &.negative {
    span {
      color: $error;
    }
  }
}
.wallet {
  flex: 1;
}
.bonus {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.25rem;
  cursor: pointer;
  input {
    display: none;
  }
  &.selected {
    .checkbox {
      background-color: $primaryDark;
      @include mixins.border('primary');
    }
    .astros {
      color: $white85;
      svg {
        fill: $white65;
      }
    }
    .tag {
      background-color: $primaryDark;
      color: $white85;
    }
  }
  &.disabled {
    cursor: not-allowed;
    .checkbox {
      opacity: 0.65;
    }
    .astros {
      color: $white45;
      svg {
        fill: $white45;
      }
    }
    .tag {
      color: $white45;
    }
  }
}
.checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.125rem;
  height: 1.125rem;
  background-color: $secondaryLight;
  border-radius: 50%;
  @include mixins.border;
  transition: all 180ms $easeOutSine;
  svg {
    width: 14px;
    height: 14px;
    fill: white;
    stroke: white;
  }
}
.astros {
  align-items: center;
  background-color: $secondaryLight;
  border-radius: 0.5rem;
  color: $white65;
  display: inline-flex;
  fill: white;
  gap: 0.5rem;
  padding: 0.25rem 0.5rem 0.25rem 0.175rem;
  position: relative;
  span {
    @extend %caption--bold;
  }
  svg {
    fill: $white45;
    width: 18px;
    height: 12px;
    transition: fill 180ms $easeOutSine;
  }
}
.tag {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  background-color: lighten($secondaryLight, 8%);
  color: $white65;
  padding: 0 0.25rem;
  border-radius: 0.25rem;
  font-size: 0.575rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: -1px;
  line-height: 0;
  white-space: nowrap;
  position: absolute;
  right: 0rem;
  bottom: -0.75rem;
  svg {
    width: 16px;
    height: 16px;
  }
}
.help {
  @extend %caption--sm;
  color: $white45;
  transition: color 180ms $easeOutSine;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  &.done {
    color: $white85;
    .icon {
      opacity: 1;
    }
    svg {
      fill: $success;
    }
  }
}
.icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  fill: currentColor;
  opacity: 0.6;
  svg {
    width: 100%;
    height: 100%;
  }
}
.spinner {
  display: inline-block;
  width: 18px;
  height: 18px;
  svg {
    width: 100%;
    height: 100%;
  }
}