@use 'src/sass/variables' as *;
@use 'src/sass/extends';

.status {
  @extend %caption--sm--bold;
  align-items: center;
  background-color: rgba($secondaryLighten, 0.2);
  border-radius: 0.25rem;
  color: white;
  display: flex;
  gap: 0.375rem;
  line-height: 1;
  padding: 0.25rem 1rem;
  span {
    background-color: $white45;
    border-radius: 50%;
    display: block;
    height: 6px;
    width: 6px;
  }
  &.open {
    span {
      background-color: $success;
    }
  }
  &.live {
    span {
      background-color: $error;
    }
  }
  &.finished {
    color: $white45;
  }
}