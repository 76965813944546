@use 'src/sass/variables' as *;
@use 'src/sass/extends';
@use 'src/sass/mixins';

.container {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  margin-bottom: 1rem;
}
.content {
  @include mixins.reset;
  align-items: center;
  background-color: lighten($secondaryLight, 4%);
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  height: 40px;
  padding: 0.5rem 0.75rem;
  position: relative;
  transition: background-color 120ms $easeOutSine;
  user-select: none;
  width: 100%;
  &:active {
    background-color: lighten($secondaryLight, 2%);
  }
}
.label {
  @extend %label;
  color: $white85;
  display: block;
}
.value {
  @extend %body;
  color: $white85;
  pointer-events: none;
}
.icon {
  fill: $white65;
  pointer-events: none;
  position: absolute;
  right: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
}
.link {
  .value {
    color: $primary;
  }
  .icon {
    display: none;
  }
}
