@use 'src/sass/variables' as *;
@use 'src/sass/mixins';

.container {
  align-items: center;
  display: flex;
  height: 100%;
  inset: 0;
  justify-content: center;
  padding: 1rem;
  position: fixed;
  width: 100%;
  z-index: 9;
}
.overlay {
  background-color: rgba(darken($secondary, 4), 0.86);
  height: 100%;
  inset: 0;
  position: absolute;
  width: 100%;
  z-index: 0;
}
.content {
  @include mixins.shadow('09dp');
  background: $secondaryLight;
  border-radius: 1.5rem;
  margin: auto;
  max-width: $maxWidth;
  padding: 1.5rem;
  position: relative;
  z-index: 1;
}
.action {
  @include mixins.reset;
  align-items: center;
  cursor: pointer;
  display: flex;
  fill: white;
  height: 40px;
  justify-content: center;
  position: absolute;
  right: -8px;
  top: -44px;
  width: 40px;
}

// CSSTransition
.container {
  &.enter {
    .overlay {
      opacity: 0;
    }
    .content {
      opacity: 0;
      transform: translateY(50%);
    }
    &Active {
      .overlay {
        opacity: 1;
        transition: opacity 120ms $easeOutQuad;
      }
      .content {
        opacity: 1;
        transform: translateY(0);
        transition: all 240ms 120ms $easeOutQuad;
      }
    }
  }
  &.exit {
    opacity: 1;
    &Active {
      opacity: 0;
      transition: opacity 240ms $easeInQuad;
    }
  }
}