@use 'src/sass/variables' as *;

// CSSTransition
.container {
  &.enter {
    left: 0;
    position: absolute;
    top: 0;
    transform: translateX(100%);
    &Active {
      transform: translateX(0);
      transition: transform 480ms $easeInQuad;
    }
  }
  &.exit {
    transform: translateX(0);
    &Active {
      transform: translateX(-50%);
      transition: transform 480ms $easeInQuad;
    }
  }
}