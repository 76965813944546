@use 'src/sass/variables' as *;
@use 'src/sass/mixins';

.container {
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: 0.5rem;
  align-items: center;
  padding: 0.5rem 1rem;
  width: 100%;
}
.welcome {
  overflow: hidden;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.avatar {
  flex: 0 0 48px;
  align-items: center;
  background-color: $secondaryLight;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  height: 48px;
  justify-content: center;
  overflow: hidden;
  width: 48px;
  img {
    display: block;
    height: 100%;
    object-fit: contain;
    width: 100%;
  }
  svg {
    fill: rgba($secondaryLighten, 0.4);
    height: 36px;
    width: 36px;
  }
}
.profile {
  overflow: hidden;
  flex: 1 0 1;
  color: $white85;
  display: flex;
  flex-direction: column;
  font-family: $fontFamilyDisplay;
  font-size: 0.875rem;
  text-wrap: nowrap;
  gap: 0.25rem;
}
.name {
  width: 100%;
  color: white;
  font-size: 1.125rem;
  font-weight: 700;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.astros {
  display: flex;
  align-items: center;
  background-color: $secondaryLight;
  border-radius: 0.5rem;
  color: white;
  fill: white;
  gap: 0.5rem;
  padding: 0.5rem 0.625rem;
  position: relative;
  span {
    font-family: $fontFamilyDisplay;
    font-size: 0.875rem;
    font-weight: 800;
  }
}
.icon {
  fill: $white65;
  width: 14px;
}
.tag {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  background-color: $primaryDark;
  padding: 0 0.25rem;
  border-radius: 0.25rem;
  font-size: 0.625rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: -1px;
  position: absolute;
  right: 0.625rem;
  bottom: -0.75rem;
  svg {
    width: 16px;
    height: 16px;
  }
}