@use 'src/sass/variables' as *;
@use 'src/sass/extends';
@use 'src/sass/mixins';

.container {
  background-color: $secondaryLight;
  border-radius: 0 0 1.5rem 1.5rem;
  margin-bottom: 1rem;
  margin-top: -0.5rem;
  padding: 0.5rem 1rem 1rem;
  position: relative;
}
.content {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 0.125rem;
}
.title {
  @extend %title--sm;
  color: white;
  line-height: 1.2;
}
.meta {
  @extend %caption--sm;
  color: $white85;
  b {
    @extend %caption--sm--bold;
  }
}
.tabs {
  bottom: -20px;
  left: 2rem;
  position: absolute;
  right: 2rem;
}
.loader {
  color: $white45;
  height: 64px;
  margin: 2rem auto 0;
  width: 64px;
  &_content {
    height: 64px;
    width: 64px;
  }
}