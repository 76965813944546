@use 'src/sass/variables' as *;
@use 'src/sass/extends';
@use 'src/sass/mixins';

.container {
  flex: 1;
  overflow: hidden;
  display: flex;
  gap: 0.75rem;
}
.content {
  padding-bottom: 0.5rem;
  cursor: pointer;
}
.label {
  @extend %label;
  color: $white65;
  margin-bottom: 0.25rem;
}
.value {
  @extend %caption--bold;
  color: $primary;
  &.active {
    color: $white85;
  }
}
.scroller {
  flex: 1;
  overflow: hidden;
  margin-right: -1rem;
}
.crop {
  height: 44px;
  overflow: hidden;
}
.scrollerContent {
  position: relative;
  padding-bottom: 40px;
  overflow-x: scroll;
  overscroll-behavior-x: contain;
  scroll-snap-type: x mandatory;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}
.items {
  width: 100%;
}
.item {
  display: inline-block;
  scroll-snap-align: start;
  width: 44px;
  height: 44px;
  padding: 0 4px 8px;
  cursor: pointer;
  &:last-child {
    visibility: hidden;
    width: 1rem;
  }
  &.active {
    .logo {
      opacity: 1;
      img {
        filter: none;
      }
    }
  }
  &.disabled {
    cursor: not-allowed;
    .logo {
      opacity: 0.45;
      img {
        filter: grayscale(100%);
      }
    }
  }
}
.logo {
  @include mixins.border();
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: lighten($secondaryLight, 12%);
  overflow: hidden;
  opacity: 0.65;
  transition: opacity 140ms $easeOutQuad;
  img {
    display: block;
    max-height: 28px;
    max-width: 28px;
    object-fit: contain;
    filter: grayscale(100%);
    transition: filter 140ms $easeOutQuad;
  }
  span {
    @extend %label;
    font-size: 0.5rem;
    color: $white85;
  }
}