@use 'src/sass/variables' as *;
@use 'src/sass/extends';
@use 'src/sass/mixins';

.container {
  background-color: $secondaryLight;
  border-radius: 0 0 1.5rem 1.5rem;
  margin-bottom: 1.5rem;
  margin-top: -0.5rem;
  padding: 0.5rem 1rem 0.75rem;
  position: relative;
}
.content {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 0.125rem;
}
.title {
  @extend %title--sm;
  color: white;
  line-height: 1.2;
}
.loader {
  color: $white45;
  height: 64px;
  margin: 2rem auto 0;
  width: 64px;
  &_content {
    height: 64px;
    width: 64px;
  }
}
.footer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 0.25rem;
}
.captain {
  align-items: center;
  display: flex;
  gap: 0.5rem;
}
.photo {
  align-items: flex-end;
  background-color: $white65;
  border-radius: 50%;
  display: flex;
  height: 40px;
  justify-content: center;
  overflow: hidden;
  width: 40px;
  img {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
  svg {
    width: 34px;
    height: 34px;
    fill: rgba($secondary, 0.65);
  }
}
.captain_name {
  @extend %caption;
  color: white;
}
.info {
  display: flex;
  gap: 1rem;
}
.meta {
  text-align: right;
}
.label {
  @extend %label;
  color: $white65;
  margin-bottom: 0.25rem;
}
.price {
  @extend %caption--bold;
  color: white;
}
.help {
  @include mixins.reset;
  width: 24px;
  height: 24px;
  position: fixed;
  top: 0.75rem;
  right: 1rem;
  z-index: 8;
  background-color: $secondaryLighten;
  border-radius: 50%;
  color: white;
  font-weight: bold;
  font-size: 0.875rem;
  cursor: pointer;
  transition: background-color 200ms $easeOutSine;
  &:active {
    background-color: darken($secondaryLighten, 5%);
  }
}