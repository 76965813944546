@use 'src/sass/variables' as *;
@use 'src/sass/extends';
@use 'src/sass/mixins';

.container {
  align-items: center;
  background-color: $secondaryLight;
  background-image: url('../../../assets/images/background_header.svg');
  background-position: center right 10%;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  height: 48px;
  justify-content: center;
  padding: 0.125rem 1rem;
  position: relative;
}
.logo {
  svg {
    fill: white;
    height: 24px;
    width: auto;
  }
}
.title {
  @extend %title--sm;
  color: white;
  line-height: 1;
}
.back {
  left: 0.5rem;
  position: absolute;
  button {
    @include mixins.reset;
    align-items: center;
    cursor: pointer;
    display: flex;
    fill: white;
    height: 40px;
    justify-content: center;
    width: 40px;
    svg {
      width: 24px;
      height: 24px;
    }
  }
}
/* .container {
  &.secondary {
    background-color: transparent;
  }
} */