@use 'src/sass/variables' as *;
@use 'src/sass/extends';
@use 'src/sass/mixins';

.container {
  padding: 0.25rem 0.25rem 0;
  width: 100%;
}
.content {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.step {
  flex-grow: 1;
  overflow: hidden;
  padding-top: 0.125rem;
  button {
    @include mixins.reset;
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    height: 40px;
    position: relative;
    transition: opacity 200ms $easeOutSine;
    width: 56px;
    z-index: 1;
    &:disabled {
      cursor: not-allowed;
      opacity: 0.45;
    }
  }
  &__content {
    position: relative;
    &::after {
      background-color: $white45;
      content: '';
      display: block;
      height: 1px;
      inset-inline-start: 56px;
      position: absolute;
      top: 8px;
      transition: background-color 200ms $easeOutSine;
      width: 300px;
      z-index: 0;
    }
  }
  &:last-child {
    flex-grow: 0;
    .step__content::after {
      display: none;
    }
  }
  &.active {
    .badge {
      background-color: $primary;
    }
    .label {
      color: white;
    }
  }
  &.done {
    .badge {
      background-color: $success;
    }
    .label {
      color: white;
    }
    .step__content::after {
      background-color: $success;
    }
  }
}
.badge {
  @extend %caption--sm--bold;
  align-items: center;
  background-color: $white45;
  border-radius: 50%;
  color: white;
  display: flex;
  height: 16px;
  justify-content: center;
  transition: background-color 200ms $easeOutSine, color 200ms $easeOutSine;
  width: 16px;
  svg {
    fill: white;
    height: 13px;
    width: 13px;
  }
}
.label {
  @extend %caption--sm--bold;
  color: $white65;
  font-size: 0.625rem;
  pointer-events: none;
  transition: color 200ms $easeOutSine;
  white-space: nowrap;
}