@use 'src/sass/variables' as *;
@use 'src/sass/extends';
@use 'src/sass/mixins';

.header {
  position: relative;
  span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    margin: 0 auto;
    background-color: $secondaryLight;
    @extend %headline--sm;
    color: rgba($secondaryLighten, 0.65);
    position: relative;
  }
  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background-color: rgba($secondaryLighten, 0.4);
    position: absolute;
    bottom: 50%;
    z-index: 0;
  }
}
.content {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.75rem;
}
.button {
  @extend %body--bold;
  @include mixins.reset;
  align-items: center;
  border-radius: 12px;
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: center;
  transition: background-color 200ms $easeOutSine;
  user-select: none;
  width: 100%;
  svg {
    margin-right: 0.375rem;
    width: 20px;
    height: 20px;
  }
  &:disabled {
    cursor: default;
    opacity: 0.25;
    pointer-events: none;
  }
}
.googleButton {
  @extend .button;
  background-color: lighten(#CDD1E0, 14%);
  color: $black85;
  flex: 0 50%;
  &:hover, &:focus {
    background-color: lighten(#CDD1E0, 18%)
  }
  &:active {
    background-color: lighten(#CDD1E0, 12%)
  }
}
.appleButton {
  @extend .button;
  background-color: white;
  color: black;
  flex: 0 50%;
  svg {
    fill: black;
  }
  &:hover, &:focus {
    background-color: lighten(#CDD1E0, 18%)
  }
  &:active {
    background-color: lighten(#CDD1E0, 12%)
  }
}