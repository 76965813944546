@use 'src/sass/variables' as *;
@use 'src/sass/extends';
@use 'src/sass/mixins';

.container {
  text-align: center
}
.content {
  background-color: lighten($secondaryLight, 8%);
  border-radius: 20px;
  display: flex;
  gap: 0.25rem;
  height: 40px;
  padding: 0.25rem;
}
.item {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex: 1 1 48px;
  justify-content: center;
  position: relative;
  white-space: nowrap;
  span {
    @extend %body--bold;
    color: $white85;
    position: relative;
    z-index: 2;
  }
  &::after {
    @include mixins.border('primary');
    background-color: $primaryDark;
    border-radius: 18px;
    content: '';
    display: block;
    height: 100%;
    opacity: 0;
    position: absolute;
    transform: scaleX(0);
    transition: all 240ms $easeOutBack;
    width: 100%;
    z-index: 1;
  }
  &.active {
    &::after {
      opacity: 1;
      transform: scaleX(1);
    }
  }
}