@use 'src/sass/variables' as *;
@use 'src/sass/extends';
@use 'src/sass/mixins';

.title {
  @extend %title--sm;
  color: white;
  margin-bottom: 0.75rem;
  text-align: center;
}
.description {
  @extend %body;
  color: $white85;
  margin-bottom: 1rem;
  b {
    @extend %body--bold;
  }
}
.button {
  margin-top: 2rem;
}
.actions {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-top: 2rem;
}
.danger {
  color: $error;
}
.graphic {
  margin-bottom: 1rem;
  text-align: center;
  img {
    height: auto;
    object-fit: contain;
    width: 160px;
  }
}