@use 'src/sass/variables' as *;
@use 'src/sass/extends';

.content {
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
  text-align: center;
}
.title {
  @extend %title--lg;
  color: white;
  font-size: 1.5rem;
}
.subtitle {
  @extend %headline--sm;
  color: $white85;
  margin-top: 0.5rem;
}
.text {
  @extend %body;
  color: $white65;
  margin-top: 0.75rem;
}
.image {
  margin: 2rem auto;
  width: 200px;
  img {
    // filter: hue-rotate(255deg) grayscale(80%);
    height: 100%;
    object-fit: contain;
    width: 100%;
  }
  text-align: center;
  color: $white45;
  font-size: 0.75rem;
}