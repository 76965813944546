@use 'src/sass/variables' as *;
@use 'src/sass/extends';
@use 'src/sass/mixins';

.skeleton {
  min-height: 120px;
}
.empty {
  margin-top: 2rem;
  text-align: center;
  h3 {
    @extend %title;
    color: $white85;
  }
  p {
    @extend %body;
    color: $white65;
    margin-top: 0.75rem;
  }
}
