@use 'src/sass/variables' as *;

.container {
  background-color: $secondary;
  margin: 0 auto;
  max-width: $maxWidth;
  width: 100%;
}

// CSSTransition
.container {
  &.enter {
    inset: 0;
    position: fixed;
    transform: translateX(100%);
    z-index: 9;
    &Active {
      transform: translateX(0);
      transition: transform 480ms $easeInQuad;
    }
  }
  &.exit {
    inset: 0;
    position: fixed;
    transform: translateX(0);
    z-index: 9;
    &Active {
      transform: translateX(-50%);
      transition: transform 480ms $easeInQuad;
    }
  }
}