@use 'src/sass/variables' as *;
@use 'src/sass/mixins';
@use 'src/sass/extends';

.container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 52px;
}
.content {
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 52px;
  justify-content: center;
  margin: 0 auto;
  position: relative;
  user-select: none;
  width: 52px;
}
.shirt {
  height: 32px;
  width: 32px;
}
.name {
  background-color: $background;
  border-radius: 0.25rem;
  padding: 1px 4px;
  position: absolute;
  top: -12px;
  span {
    @extend %caption--sm;
    color: white;
    display: block;
    font-size: 0.625rem;
    position: relative;
    text-align: center;
    white-space: nowrap;
    z-index: 1;
  }
  &::after {
    background-color: $background;
    bottom: -2px;
    content: '';
    display: block;
    height: 8px;
    position: absolute;
    right: calc(50% - 4px);
    transform: rotate(45deg);
    width: 8px;
    z-index: 0;
  }
}
.price {
  bottom: -6px;
  position: absolute;
  span {
    @extend %caption--sm--bold;
    color: white;
    font-size: 0.625rem;
    text-shadow: 1px 1px 1px rgba(#000, 0.45);
  }
}
.points {
  bottom: -8px;
  position: absolute;
  @extend %caption--sm;
  color: white;
  font-size: 0.75rem;
  text-shadow: 1px 1px 1px rgba(#000, 0.45);
  span {
    @extend %caption--sm--bold;
  }
}
.badge {
  width: 18px;
  height: 18px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
  outline: 2px solid white;
  svg {
    height: 100%;
    object-fit: contain;
    width: 100%;
  }
}
.remove {
  @include mixins.reset;
  position: absolute;
  right: 0;
  bottom: -28px;
  left: 0;
  margin: auto;
  width: 20px;
  height: 20px;
  background-color: rgba($secondary, 0.5);
  border-radius: 50%;
  border: 2px solid white;
  fill: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  svg {
    width: 18px;
    height: 18px;
  }
}

@media (min-width: 375px) {
  .content {
    height: 64px;
    width: 64px;
  }
  .shirt {
    height: 44px;
    width: 44px;
  }
  .name {
    padding: 2px 4px;
  }
}