@import 'src/sass/variables';

@mixin media($media) {
  @if $media == 'sm' {
    @media (min-width: map-get($breakpoints, 'sm')) { @content; }
  }
  @else if $media == 'md' {
    @media (min-width: map-get($breakpoints, 'md')) { @content; }
  }
  @else if $media == 'lg' {
    @media (min-width: map-get($breakpoints, 'lg')) { @content; }
  }
  @else if $media == 'xl' {
    @media (min-width: map-get($breakpoints, 'xl')) { @content; }
  }
  @else if $media == 'xxl' {
    @media (min-width: map-get($breakpoints, 'xxl')) { @content; }
  }
}

@mixin shadow($elevation) {
  @if $elevation == '01dp' {
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  }
  @if $elevation == '03dp' {
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -3px rgba(0, 0, 0, 0.12), 0 1px 8px 0 rgba(0, 0, 0, 0.2);
  }
  @if $elevation == '06dp' {
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0px rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
  }
  @if $elevation == '09dp' {
    box-shadow: 0 9px 12px 1px rgba(0, 0, 0, 0.14), 0 3px 16px 2px rgba(0, 0, 0, 0.12), 0 5px 6px -3px rgba(0, 0, 0, 0.2);
  }
  @if $elevation == '12dp' {
    box-shadow: 0 12px 17px 2px rgba(0, 0, 0, 0.14), 0 5px 22px 4px rgba(0, 0, 0, 0.12), 0 7px 8px -4px rgba(0, 0, 0, 0.2);
  }
  @if $elevation == '16dp' {
    box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
  }
  @if $elevation == '24dp' {
    box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 11px 15px -7px rgba(0, 0, 0, 0.2);
  }
}

@mixin border($color: 'secondary') {
  @if $color == 'primary' {
    border: 1px solid $primary;
  }
  @else {
    border: 1px solid rgba($secondaryLighten, 0.4);
  }
}

@mixin reset {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
}
