@use 'src/sass/variables' as *;
@use 'src/sass/extends';
@use 'src/sass/mixins';

.container {
  gap: 0.5rem;
  &.preview {
    margin-top: -1.5rem;
    .content {
      position: relative;
      &::before {
        background: linear-gradient(rgba($secondary, 0) -10%, rgba($secondary, 0.5) 37.33%, rgba($secondary, 1) 100%);
        content: '';
        display: block;
        inset: 0;
        position: absolute;
        z-index: 2;
      }
    }
  }
}
.fixed {
  position: -webkit-sticky;
  position: sticky;
  top: 48px;
  z-index: 2;
  background-color: $secondary;
  margin-top: -1.5rem;
}
.card {
  padding-top: 1.25rem;
  padding-bottom: 0.25rem;
}
.header {
  display: flex;
  justify-content: space-between;
  line-height: 1;
  padding: 0.75rem 0.75rem 0.25rem;
  span {
    @extend %caption--sm;
    color: $white65;
  }
}
.content {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}
.skeleton {
  min-height: 66px;
}
.empty {
  margin-top: 2rem;
  text-align: center;
  h3 {
    @extend %title;
    color: $white85;
  }
  p {
    @extend %body;
    color: $white65;
    margin-top: 0.75rem;
  }
}