@use 'src/sass/variables' as *;
@use 'src/sass/extends';
@use 'src/sass/mixins';

.container {
  margin-top: -0.25rem;
}
.name {
  @extend %body--bold;
  color: white;
  margin-bottom: 0.5rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  a {
    color: $primary;
  }
}
.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.captain {
  align-items: center;
  display: flex;
  gap: 0.5rem;
}
.photo {
  align-items: flex-end;
  background-color: $white65;
  border-radius: 50%;
  display: flex;
  height: 40px;
  justify-content: center;
  overflow: hidden;
  width: 40px;
  img {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
  svg {
    width: 34px;
    height: 34px;
    fill: rgba($secondary, 0.65);
  }
}
.captain_name {
  @extend %caption;
  color: white;
}
.info {
  display: flex;
  gap: 1rem;
}
.meta {
  text-align: right;
}
.label {
  @extend %label;
  color: $white65;
  margin-bottom: 0.25rem;
}
.price {
  @extend %caption--bold;
  color: white;
}
.empty {
  margin: 2rem 0 1.5rem;
  text-align: center;
  h3 {
    @extend %title;
    color: $white85;
  }
  p {
    @extend %body;
    color: $white65;
    margin-top: 0.75rem;
  }
}
.sk_header {
  margin-bottom: 1.5rem;
  min-height: 4rem;
}
.sk_field {
  margin: 0 auto 1rem;
  min-height: calc(296px * 1.67);
}
@media (min-width: 375px) {
  .sk_field {
    min-height: calc(327px * 1.67);
    width: 327px;
  }
}
@media (min-width: 390px) {
  .sk_field {
    min-height: calc(342px * 1.67);
    width: 342px;
  }
}
@media (min-width: 410px) {
  .sk_field {
    min-height: calc(380px * 1.67);
    width: 380px;
  }
}