@use 'src/sass/variables' as *;
@use 'src/sass/extends';
@use 'src/sass/mixins';

.container {
  padding-top: 0;
}
.graphic {
  margin-bottom: 1.5rem;
  text-align: center;
  img {
    height: auto;
    object-fit: contain;
    width: 160px;
  }
}
.content {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  p {
    @extend %body--lg;
    color: $white85;
    text-align: center;
    b {
      font-weight: 700;
    }
  }
}
.tip {
  display: flex;
  gap: 0.5rem;
  p {
    @extend %caption;
    color: $white65;
    text-align: left;
  }
}
.icon {
  svg {
    fill: $white65;
    height: 28px;
    width: 28px;
  }
}
.counter {
  background-color: $secondaryLight;
  border-radius: 1rem;
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  margin-bottom: 0.25rem;
  padding: 0.5rem;
}
.item {
  background-color: rgba($secondaryLighten, 0.2);
  border-radius: 0.5rem;
  color: white;
  min-width: 3.5rem;
  padding: 0.5rem;
  text-align: center;
}
.label {
  @extend %caption--sm;
  color: $white85;
}
.value {
  @extend %title--lg;
  color: white;
  font-size: 1.5rem;
}