@use 'src/sass/variables' as *;
@use 'src/sass/extends';
@use 'src/sass/mixins';

.title {
  @extend %title--sm;
  color: white;
  margin-bottom: 0.75rem;
  text-align: center;
}
.description {
  @extend %body;
  color: $white85;
  margin-bottom: 1rem;
  b {
    @extend %body--bold;
  }
}
.button {
  margin-top: 2rem;
}
.crop {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  margin-top: 1.5rem;
}
.scale {
  @include mixins.reset;
  &:focus {
    outline: none;
  }
  &::-webkit-slider-runnable-track {
    background: $secondary;
    border: 1px solid $secondaryLighten;
    border-radius: 1px;
    width: 100%;
    height: 5px;
    cursor: pointer;
  }
  &:hover {
    &::-webkit-slider-runnable-track {
      background: $primaryDarken;
      border: 1px solid $primary;
    }
    &::-moz-range-track {
      background: $primaryDarken;
      border: 1px solid $primary;
    }
  }
  &::-webkit-slider-thumb {
    @include mixins.reset;
    margin-top: -10px;
    width: 23px;
    height: 23px;
    background: $primaryDark;
    border: 2px solid $primary;
    border-radius: 12px;
    cursor: pointer;
  }
  &::-moz-range-track {
    background: $secondary;
    border: 1px solid $secondaryLighten;
    border-radius: 1px;
    width: 100%;
    height: 4px;
    cursor: pointer;
  }
  &::-moz-range-thumb {
    @include mixins.reset;
    width: 20px;
    height: 20px;
    background: $primaryDark;
    border: 2px solid $primary;
    border-radius: 12px;
    cursor: pointer;
  }
}
.version {
  @extend %caption--sm;
  text-align: center;
  color: $white65;
  p {
    margin-bottom: 0.5rem;
  }
}
