@use 'src/sass/variables' as *;
@use 'src/sass/extends';
@use 'src/sass/mixins';

.container {
  @include mixins.border();
  background-color: $secondaryLight;
  background-image: url('../../assets/images/background_header.svg');
  background-position: top 16% right 4%;
  background-repeat: no-repeat;
  border-radius: 1rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.75rem 1rem;
  position: relative;
  transition: background-color 120ms $easeOutSine;
  user-select: none;
  &:active {
    background-color: darken($secondaryLight, 2%);
  }
}
.title {
  @extend %headline--bold;
  color: white;
}
.round {
  @extend %body;
  color: $white85;
  b {
    @extend %body--bold;
  }
}
.footer {
  @extend %caption--sm;
  color: $white85;
  display: flex;
  gap: 1rem;
  margin-bottom: 0.25rem;
  b {
    @extend %caption--sm--bold;
  }
}
.icon {
  height: 24px;
  position: absolute;
  right: 0.5rem;
  top: calc(50% - 12px);
  width: 24px;
  svg {
    fill: rgba($secondaryLighten, 0.8);
    height: 100%;
    width: 100%;
  }
}
.badge {
  text-align: right;
}
.rank {
  @include mixins.border('primary');
  align-items: center;
  background-color: $primaryDark;
  border-radius: 0.75rem 0 0 0.75rem;
  display: inline-flex;
  gap: 0.75rem;
  margin-right: -1rem;
  padding: 0.25rem 1rem;
  svg {
    fill: $white85;
    height: 28px;
    width: 28px;
  }
  &_item {
    display: flex;
    flex-direction: column;
  }
  &.finished {
    background-color: lighten($secondary, 5%);
    border-color: $secondaryLighten;
  }
}
.label {
  @extend %caption--sm;
  color: $white65;
}
.value {
  @extend %caption--lg--bold;
  color: white;
}
.cta {
  @extend %caption--bold;
  @include mixins.border('primary');
  background-color: $primaryDark;  
  border-radius: 0.75rem 0 0 0.75rem;
  color: white;
  display: inline-block;
  margin-right: -1rem;
  padding: 0.275rem 1rem;
}