@use 'src/sass/variables' as *;
@use 'src/sass/extends';
@use 'src/sass/mixins';

.container {
  position: relative;
  background-color: $secondaryLight;
  background-size: cover;
  background-position: bottom center;
  overflow: hidden;
  border-radius: 1rem;
  width: 190px;
  height: 100px;
  cursor: pointer;
  user-select: none;
}
.title {
  position: absolute;
  top: 8px;
  background-color: $secondary;
  border-radius: 0 4px 4px 0;
  padding: 2px 4px;
  @extend %caption--sm;
  color: white;
}
.description {
  @extend %caption--sm--bold;
  line-height: 1.2;
  white-space: initial;
  color: white;
  position: absolute;
  right: 0;
  bottom: 0;
  text-align: right;
  padding: 0.625rem;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
  strong {
    @extend %caption--lg--bold;
  }
}
.logo {
  position: absolute;
  top: 0;
  right: 0;
  left: auto;
  border-radius: 0 0 0 4px;
  padding: 0.125rem 0.5rem;
  fill: $secondary;
  img {
    width: auto;
    height: 40px;
  }
}
.modal {
  padding: 0;
  width: 300px;
  &_content {
    min-height: 240px;
    padding: 0.5rem 1rem 1.25rem 1rem;
    border-radius: 1.5rem;
    background-size: cover;
    background-position: bottom center;
    position: relative;
    color: white;
    overflow: hidden;
  }
  &_logo {
    text-align: center;
    img {
      width: auto;
      height: 72px;
    }
  }
  &_title {
    @extend %caption--bold;
    position: absolute;
    top: 0;
    left: 0;
    background-color: $primary;
    border-radius: 0 0 0 8px;
    padding: 6px 8px 6px 6px;
    transform: rotate(-90deg) translate(-100%, 0);
    transform-origin: top left;
  }
  &_description {
    @extend %body--lg;
    line-height: 1.4;
    white-space: initial;
    color: white;
    text-align: center;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
    margin-bottom: 1rem;
    font-weight: 600;
    strong {
      @extend %title--lg;
    }
  }
  &_footer {
    @extend %caption;
    white-space: initial;
    p {
      text-align: right;
    }
  }
  &_button {
    margin-top: 1rem;
    button {
      width: auto;
      padding: 0 1.25rem;
      margin: 0 0 0 auto;
    }
  }
}