@use 'src/sass/variables' as *;
@use 'src/sass/extends';
@use 'src/sass/mixins';

.content {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}
.title {
  @extend %headline;
  color: white;
  text-align: center;
}
.text {
  @extend %body;
  color: $white85;
  b {
    @extend %body--bold;
  }
}
.input {
  margin-bottom: 2rem;
}