@use 'src/sass/variables' as *;
@use 'src/sass/extends';
@use 'src/sass/mixins';

.container {
  background-color: $secondaryLight;
  overflow: hidden;
  border-radius: 1rem;
  cursor: pointer;
}
.image {
  width: 100%;
  height: auto;
  max-height: 160px;
  object-fit: cover;
  border-radius: 1rem;
}