@use 'src/sass/variables' as *;
@use 'src/sass/extends';

.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  max-height: -moz-available;
  max-height: -webkit-fill-available;
  max-height: stretch;
}
.header {
  align-items: center;
  background-image: url('../../assets/images/background_auth.svg');
  background-position: top center;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  flex-grow: 1;
  justify-content: center;
}
.logotipo {
  fill: white;
  width: 224px;
}
.content {
  background-color: $secondaryLight;
  border-radius: 1.5rem 1.5rem 0 0;
  display: flex;
  flex-direction: column;
  padding: 2.25rem 1.25rem 1.25rem;
  position: relative;
}
.legal {
  margin-top: 0.5rem;
  color: $white65;
  @extend %caption--sm;
  text-align: center;
  p {
    margin-top: 0.25rem;
  }
  a {
    &:hover {
      text-decoration: underline;
    }
  }
}
.tabs {
  left: 2rem;
  position: absolute;
  right: 2rem;
  top: -20px;
  z-index: 1;
}