@use 'src/sass/variables' as *;
@use 'src/sass/extends';
@use 'src/sass/mixins';

.container {
  @extend %body--bold;
  @include mixins.reset;
  align-items: center;
  background-color: $primary;
  border-radius: 12px;
  color: white;
  cursor: pointer;
  display: flex;
  gap: 0.5rem;
  height: 40px;
  justify-content: center;
  transition: background-color 200ms $easeOutSine, color 200ms $easeOutSine;
  user-select: none;
  width: 100%;
  &.secondary {
    background-color: lighten($secondaryLight, 4%);
    &:active {
      background-color: lighten($secondaryLight, 2%);
    }
  }
  &:active {
    background-color: $primaryDark;
  }
  &:disabled {
    background-color: lighten($secondaryLight, 2%);
    color: $white45;
    cursor: not-allowed;
  }
}
.icon {
  fill: white;
  height: 24px;
  width: 24px;
}
.loader {
  fill: $white85;
  height: 32px;
  margin-right: -0.5rem;
  transition: all 240ms $easeOutBack;
  width: 0;
  &.active {
    margin-right: 0;
    width: 32px;
  }
}
