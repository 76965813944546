@use 'src/sass/variables' as *;

.container {
  align-items: center;
  display: flex;
  justify-content: center;
}
.item {
  cursor: pointer;
  padding: 0.5rem;
  span {
    background-color: $white65;
    border-radius: 2px;
    display: block;
    height: 4px;
    overflow: hidden;
    text-indent: -999px;
    transition: width 400ms $easeInQuart, background-color 300ms 200ms $easeInQuart;
    width: 30px;
  }
  &.isActive {
    span {
      background-color: $primary;
      width: 56px;
    }
  }
}